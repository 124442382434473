import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import SymptomForm from "./SymptomForm"
import { PrescriptionDetail, PrescriptionDetailCategory } from "./types"

type notPrescribeReason = {
  value: string
  options: [string[]]
}

type medicalExaminationCategory = {
  id: string
  name: string
}

type Props = {
  medicalExaminationId: string
  mainMedicalExaminationCategory: medicalExaminationCategory
  medicalExaminationCategories: medicalExaminationCategory[]
  notPrescribeReason?: notPrescribeReason
  forModal: boolean
}

const PrescriptionForm: React.FC<Props> = (props) => {
  const mainCategory = props.mainMedicalExaminationCategory.name
  const MEDICINE_CATEGORY_DAYS = {
    AGA: 28,
    "AGA(女性)": 30,
  }
  const [isPrescribing, setIsPrescribing] = useState<boolean>(props.forModal)
  const [isSplitDispensing, setIsSplitDispensing] = useState<boolean>(true)
  const notPrescribeReasonData = props.notPrescribeReason as notPrescribeReason
  const medicalExaminationCategories = props.medicalExaminationCategories as medicalExaminationCategory[]
  const [count, setCount] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<Array<string>>([])
  const [
    prescriptionDetailCategories,
    setPrescriptionDetailCategories,
  ] = useState<Array<PrescriptionDetailCategory>>([])

  const handleButtonClick = () => {
    setCount(count + 1)
  }
  const [selectedCategoryNames, setSelectedCategoryNames] = useState<
    Array<string>
  >([])

  useEffect(() => {
    const selectedCategoryNames = prescriptionDetailCategories.map(
      (detailCategory) => detailCategory.name
    )
    setSelectedCategoryNames(selectedCategoryNames)
  }, [prescriptionDetailCategories])

  const updatePrescriptionDetails = (
    index: number,
    medicalExaminationCategory: string,
    prescriptionDetails: Array<PrescriptionDetail>
  ) => {
    const newCategories = [...prescriptionDetailCategories]
    const newPrescriptionDetailCategory = {
      name: medicalExaminationCategory,
      prescriptionDetails: prescriptionDetails,
    }

    if (index < newCategories.length) {
      newCategories[index] = newPrescriptionDetailCategory
    } else {
      newCategories.push(newPrescriptionDetailCategory)
    }
    setPrescriptionDetailCategories(newCategories)
  }

  const validate = () => {
    const errors: Array<string> = []
    // メイン・サブ共通 規格の選択が必要なもので未選択のものをチェックする
    prescriptionDetailCategories.map((prescriptionDetailCategory) => {
      prescriptionDetailCategory.prescriptionDetails.map(
        (prescriptionDetail) => {
          if (
            prescriptionDetail.shouldPrescribe &&
            prescriptionDetail.medicineDetailId === null
          ) {
            errors.push(
              `${prescriptionDetail.medicineName}の用量を選択してください`
            )
          }
        }
      )
    })

    // AGAの場合
    //   処方にチェックがある方をメインで処方する診療カテゴリー
    //   2つもとチェックの場合は mainCategory = メインで処方する診療カテゴリー(同時には処方できない メインの薬のバリデーションチェックのため)
    // AGA以外の場合 mainCategory = メインで処方する診療カテゴリー
    let mainPrescriptionDetailCategory
    if (mainCategory.includes("AGA")) {
      // AGAとAGA(女性)が同時に選択されていないかをチェックする
      const selectedAGA = prescriptionDetailCategories.filter(
        (prescriptionDetailCategory) => {
          const prescribedDetails = prescriptionDetailCategory.prescriptionDetails.filter(
            (detail) => detail.shouldPrescribe === true
          )
          return (
            prescriptionDetailCategory.name.includes("AGA") &&
            prescribedDetails.length
          )
        }
      )
      if (selectedAGA.length === 1) {
        mainPrescriptionDetailCategory = selectedAGA[0]
      } else if (selectedAGA.length > 1) {
        mainPrescriptionDetailCategory = selectedAGA.find(
          (category) => category.name === mainCategory
        )
        errors.push("AGAとAGA(女性)は同時に選択できません")
      }
    } else {
      mainPrescriptionDetailCategory = prescriptionDetailCategories.find(
        (prescriptionDetailCategory) =>
          prescriptionDetailCategory.name === mainCategory
      )
    }

    // メインの薬に対して以下をチェックする
    // 1. 薬の処方にチェックが入っているか
    // 2. 処方月数が同じになっているか
    // 3. 分割調剤で処方できる日数(1ヶ月から6ヶ月)になっているか
    if (!mainPrescriptionDetailCategory) {
      errors.push(`${mainCategory}の薬が選択されていません`)
    } else {
      const mainPrescribedDetails = mainPrescriptionDetailCategory.prescriptionDetails.filter(
        (prescriptionDetail: PrescriptionDetail) => {
          return prescriptionDetail.shouldPrescribe === true
        }
      )

      if (mainPrescribedDetails.length === 0) {
        errors.push(
          `${mainPrescriptionDetailCategory.name}の薬が選択されていません`
        )
      } else {
        const prescribedMonths = mainPrescribedDetails.map(
          (prescribedDetail) => {
            if (
              prescribedDetail.dosageKey === "dosage_per_day" &&
              prescribedDetail.day
            ) {
              return (
                prescribedDetail.day /
                MEDICINE_CATEGORY_DAYS[mainPrescriptionDetailCategory.name]
              )
            } else {
              return prescribedDetail.dosage
            }
          }
        )

        const numbersOnlyMonths: number[] = prescribedMonths.filter(
          (item): item is number => item !== null
        )

        if (
          numbersOnlyMonths.every(
            (prescribedMonth) => prescribedMonth === numbersOnlyMonths[0]
          )
        ) {
        } else {
          errors.push(
            `${mainPrescriptionDetailCategory.name}で処方する薬の処方日数が異なります`
          )
        }

        const minMonth = Math.min(...numbersOnlyMonths)
        const maxMonth = Math.max(...numbersOnlyMonths)

        if (isSplitDispensing === true && (minMonth < 1 || maxMonth > 6)) {
          errors.push(
            "分割調剤の場合、処方月数は1ヶ月から6ヶ月で選択する必要があります"
          )
        }
      }
    }

    if (errors.length) {
      setErrorMessages(errors)
      return false
    } else {
      return true
    }
  }

  window.showModalPrescribedContent = () => {
    if (!isPrescribing) {
      submit()
      return
    }

    if (validate()) {
      setIsModalOpen(true)
    } else {
      setIsErrorModalOpen(true)
    }
  }

  const submit = () => {
    const form = document.getElementById("medical_examination_form")
    if (form && form instanceof HTMLFormElement) {
      form.submit()
    }
  }
  const cancel = () => {
    setIsModalOpen(false)
  }

  const handleOnChangeSplitDispensing = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.checked) {
      const answer = confirm(
        "分割調剤のチェックを外します\nよろしいですか？\nAGAスマクリは分割調剤を推奨しています。"
      )
      if (!answer) return
    }
    setIsSplitDispensing(e.target.checked)
  }

  const prescriptionDetailRow = (
    prescriptionDetail: PrescriptionDetail,
    index: number
  ) => {
    if (!prescriptionDetail.shouldPrescribe) {
      return
    }
    return (
      <tr key={index}>
        <td>{prescriptionDetail.medicineName}</td>
        <td>
          {prescriptionDetail.specAmount}
          {prescriptionDetail.specUnit}
        </td>
        <td>
          {prescriptionDetail.dosage}
          {prescriptionDetail.dosageUnit}
        </td>
        <td>
          {prescriptionDetail.useTimesPerDay === 0
            ? "頓用"
            : `${prescriptionDetail.useTimesPerDay}回/日`}
        </td>
        <td>
          {prescriptionDetail.useCase
            ?.map((useCase) => useCase.value)
            .join(",")}
        </td>
        <td>{prescriptionDetail.dayLabel}</td>
      </tr>
    )
  }

  const selectedPrescriptionDetails = prescriptionDetailCategories.map(
    (prescriptionDetailCategory, index) => {
      const hasPrescribed = prescriptionDetailCategory.prescriptionDetails.some(
        (detail) => detail.shouldPrescribe === true
      )
      return (
        hasPrescribed && (
          <div key={index}>
            <h4>診療内容: {prescriptionDetailCategory.name}</h4>
            <Table>
              <thead>
                <tr>
                  <th>薬名</th>
                  <th>用量</th>
                  <th>処方量</th>
                  <th>服用回数</th>
                  <th>服用タイミング</th>
                  <th>処方日数</th>
                </tr>
              </thead>
              <tbody>
                {prescriptionDetailCategory.prescriptionDetails.map(
                  (prescriptionDetail: PrescriptionDetail, i: number) => {
                    return prescriptionDetailRow(prescriptionDetail, i)
                  }
                )}
              </tbody>
            </Table>
          </div>
        )
      )
    }
  )

  return (
    <>
      {!props.forModal && (
        <FormGroup>
          <Row className="align-items-baseline">
            <Col md={2} className="col-form-label col-md-2 bg-light">
              処方箋
            </Col>
            <Col md={10}>
              <Label className="ml-4">
                <Input
                  type="radio"
                  checked={isPrescribing}
                  onChange={() => setIsPrescribing(true)}
                />
                処方箋を発行する
              </Label>
              <Label className="ml-4">
                <Input
                  type="radio"
                  checked={!isPrescribing}
                  onChange={() => setIsPrescribing(false)}
                />
                処方箋を発行しない
              </Label>
            </Col>
          </Row>
        </FormGroup>
      )}
      {isPrescribing ? (
        <>
          <FormGroup>
            <Row className="align-items-baseline">
              <Col md={2} className="col-form-label bg-light">
                分割調剤
              </Col>
              <Col md={2} className="d-flex align-items-end">
                <Input
                  name="medical_examination[prescriptions_attributes][0][is_split_dispensing]"
                  value={isSplitDispensing.toString()}
                  type="hidden"
                />
                <Input
                  className="m-0"
                  type="checkbox"
                  checked={isSplitDispensing}
                  onChange={(e) => handleOnChangeSplitDispensing(e)}
                />
              </Col>
            </Row>
          </FormGroup>
          {Array.from({ length: count }).map((_, index) => (
            <SymptomForm
              key={index}
              index={index}
              medicalExaminationId={props.medicalExaminationId}
              medicalExaminationCategories={
                index === 0
                  ? [props.mainMedicalExaminationCategory]
                  : [{ id: "", name: "" }].concat(medicalExaminationCategories)
              }
              medicalExaminationCategory={index === 0 ? mainCategory : ""}
              selectedCategoryNames={selectedCategoryNames}
              onChange={updatePrescriptionDetails}
            />
          ))}
          <FormGroup>
            <Row className="justify-content-center">
              <Button color="success" onClick={handleButtonClick}>
                ＋別の症状の処方箋の発行を追加する
              </Button>
            </Row>
          </FormGroup>
          <Modal isOpen={isErrorModalOpen} size="xl">
            <ModalHeader>処方箋の入力エラー</ModalHeader>
            <ModalBody>
              {errorMessages.map((errorMessage, index) => {
                return <p key={index}>{errorMessage}</p>
              })}
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => setIsErrorModalOpen(false)}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={isModalOpen} size="xl">
            <ModalHeader>処方箋の内容確認</ModalHeader>
            <ModalBody>
              <p>
                以下の内容で処方箋を作成します。
                <br />
                よろしいですか？
              </p>
              {selectedPrescriptionDetails}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={submit}>
                保存
              </Button>
              <Button color="secondary" onClick={cancel}>
                キャンセル
              </Button>
            </ModalFooter>
          </Modal>
        </>
      ) : (
        <FormGroup>
          <Row className="align-items-baseline">
            <Col md={2} className="col-form-label col-md-2 bg-light">
              未処方理由
            </Col>
            <Col md={10}>
              <Input
                name="medical_examination[not_prescribe_reason]"
                className="select2"
                type="select"
                defaultValue={notPrescribeReasonData?.value || ""}
              >
                <option value="" disabled={true}>
                  選択してください
                </option>
                {notPrescribeReasonData?.options?.map((item) => {
                  return (
                    <option value={item[1]} key={item[1]}>
                      {item[0]}
                    </option>
                  )
                })}
              </Input>
            </Col>
          </Row>
        </FormGroup>
      )}
    </>
  )
}

export default PrescriptionForm
